// Styles
import 'babel-polyfill';
import '../css/main.scss';
import '../css/custom.scss';
import '../css/smartbanner.css';
import '../css/material-overrides.scss';

// Material Design Components
import { MDCTextField, MDCTextFieldIcon } from '@material/textfield';
import { MDCCheckbox } from '@material/checkbox';
import { MDCFormField } from '@material/form-field';
import { MDCSelect } from '@material/select';
import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';

// Scripts
import 'popper.js';
import * as $ from 'jquery/dist/jquery';
window.$ = window.jquery = window.jQuery = $;
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/alert';
import { MDCDialog } from "@material/dialog/dist/mdc.dialog";

let dialog;
window.openDialog = openDialog;
function openDialog() {
    dialog.open();
}

$(document).ready(() => {
    const textFields = $('.mdc-text-field');
    for (const tf of textFields) {
        new MDCTextField(tf);
    }

    const formFields = $('.mdc-form-field');
    for (const ff of formFields) {
        new MDCFormField(ff);
    }

    const checkboxes = $('.mdc-checkbox');
    for (const cb of checkboxes) {
        new MDCCheckbox(cb);
        const ff = $(cb).parent();
        ff.input = cb;
    }

    const selects = $('.mdc-select');
    window.loadedSelects = []; // store a global reference so that we can access these later if needed
    for (const s of selects) {
        window.loadedSelects.push(new MDCSelect(s));
    }

    const lists = $('.mdc-list');
    for (const list of lists) {
        const listItem = new MDCList(list);
        listItem.listElements.map((listItemEl) => new MDCRipple(listItemEl));
    }

    const icons = $('.mdc-text-field-icon');
    for (const icon of icons) {
        new MDCTextFieldIcon(icon);
    }

    const buttons = $('.mdc-button');
    for (const button of buttons) {
        new MDCRipple(button);
    }
    
    dialog = $('.mdc-dialog')[0];
    if (dialog) {
        new MDCDialog(dialog);
    }
});
